import styled, { css } from "styled-components";
import media from "../../lib/theme/media";
import { Container } from "../Common/Grid";

interface FooterProps {
  dark?: boolean;
  theme?: any;
}

export const FooterContainer = styled(Container)`
  padding: 3.5rem 0;
  display: grid;
  font-size: 1rem;
  line-height: 1.875rem;
  position: relative;
  z-index: 1;
  grid-row-gap: 2rem;

  @media ${media.medium} {
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 1.875rem;
    grid-row-gap: 0;
  }
`;

export const FooterNav = styled.nav`
  display: grid;
  grid-row-gap: 1.825rem;

  @media ${media.medium} {
    grid-template-columns: 1.4fr 1fr 1fr;
    grid-column-gap: 1.875rem;
    grid-row-gap: 0;
  }
`;

export const FooterSocialIconsBox = styled.div`
  padding-top: 1rem;

  & > a {
    display: inline-block;
    padding: 0 0.3rem;
    margin-bottom: -0.5rem;
  }

  @media ${media.medium} {
    padding-top: 1.875rem;
  }
`;

export const FooterLinksHeading = styled.p`
  font-weight: bold;
  line-height: 2rem;
`;

export const FooterLink = styled.a`
  text-decoration: none;
  opacity: 0.75;
  cursor: pointer;
  will-change: opacity;
  transition: opacity 250ms;

  &:hover {
    opacity: 1;
  }
`;

export const FooterCopyrightBox = styled.div`
  & > p {
    padding-top: 1.25rem;
    opacity: 0.3;
  }
`;

export const FooterBackgroundIcon = styled.img`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-72%, -50%);
  height: 90%;
  z-index: -1;
`;

export const Footer = styled.footer`
  border-top: 1px solid rgba(112, 133, 202, 0.3);
  position: relative;
  ${getBacgroundColor}

  ${FooterLinksHeading} {
    ${getColors}
  }

  ${FooterLink} {
    ${getColors}
  }

  ${FooterCopyrightBox} {
    & > p {
      ${getColors}
    }
  }
`;

function getBacgroundColor(props: FooterProps) {
  let backgroundColor;
  if (props.dark) {
    backgroundColor = css`
      background-color: ${props.theme.colors.primary};
    `;
  } else {
    backgroundColor = css`
      background-color: #f0f4f8;
    `;
  }

  return backgroundColor;
}

function getColors(props: FooterProps) {
  let colors;
  if (props.dark) {
    colors = css`
      color: ${props.theme.colors.text_white};
    `;
  } else {
    colors = css`
      color: ${props.theme.colors.primary};
    `;
  }

  return colors;
}
