import { MenuList, MenuButton, MenuLink, MenuItem } from "@reach/menu-button";
import styled, { css } from "styled-components";
import media from "../../../../lib/theme/media";

export const UserDropdownWrapper = styled.div`
  height: 35px;
  padding-left: 20px;
  position: relative;
  width: 50px;

  [data-reach-menu-popover] {
    z-index: 3;
    position: relative;
    top: -35px;
    min-width: 238px;
    transform: translateX(calc(-100% + 75px));
  }

  @media ${media.xxLarge} {
    width: 125px;
    padding-left: 50px;
  }
`;

export const UserDropdownMenuButton = styled(MenuButton)`
  background-color: transparent;
  border: solid 1px transparent;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:focus {
    border: none;
  }

  & > img:first-child {
    width: 2rem;
    height: 2rem;
  }

  & > img:last-child {
    transform: rotate(180deg);
    opacity: 0.6;
  }
`;

export const UserDropdownMenuList = styled(MenuList)`
  overflow: hidden;
  box-shadow: 0 2px 9px 0 rgba(72, 69, 88, 0.23);
  border: solid 1px #e4e8ed;
  border-radius: 8px;
  padding: 0;
  outline: none !important;
  background-color: #fff;
`;

export const DropdownItemStyles = css`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors["text_dark"]};

  cursor: pointer;

  &:hover,
  &:focus,
  &[data-selected] {
    font-weight: bold;
    & > img {
      opacity: 1;
      border-color: rgba(8, 19, 47, 0.1);
    }
  }

  & > span {
    margin-top: 5px;
    margin-left: 0.825rem;
  }

  & > img {
    padding: 0.125rem;
    border-radius: 8px;
    border: solid 1px rgba(8, 19, 47, 0.35);
    opacity: 0.3;
  }
`;

export const UserDropdownMenuLink = styled(MenuLink)`
  ${DropdownItemStyles}
`;

export const UserDropdownMenuItem = styled(MenuItem)`
  ${DropdownItemStyles}
`;

export const StyledUserDropdownLink = styled.a`
  ${DropdownItemStyles}
`;
