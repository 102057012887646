import React, { useEffect, useState } from "react";
import Link from "next/link";
// import { useRouter } from "next/router";
import { useAuth } from "lib/auth/authContext";
import { roleIsAtLeast } from "lib/helpers";
import Routes from "lib/routes";
import logoBlack from "assets/logos/logo-black.svg";
import {
  StylBurger,
  StylNavbar,
  HeaderContainer,
  HeaderColumn,
} from "./Header.styles";
import HeaderBottomNav from "./HeaderBottomNav";
import HeaderMainNav from "./HeaderMainNav";
import { AdminPages, TeacherPages } from "./Pages";
import UserDropdown from "./UserDropdown/UserDropdown.component";
import ViewToggle from "./ViewToggle";

export type ActiveNavIndex = null | number;

function HeaderComponent({ wide }: { wide?: boolean }) {
  const { user } = useAuth();
  const pages =
    user && roleIsAtLeast(user.role).researcher() ? AdminPages : TeacherPages;

  // const { pathname } = useRouter();

  const [activeBottomNav, setActiveBottomNav] = useState<ActiveNavIndex>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function handleToggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  function toggleBottomNav(index: number): void {
    if (index === activeBottomNav) setActiveBottomNav(null);
    else setActiveBottomNav(index);
  }

  useEffect(() => {
    if (window.innerWidth >= 1024) {
      setIsMenuOpen(true);
    }
  }, []);

  return (
    <header>
      <HeaderContainer wide={wide} isMenuOpen={isMenuOpen}>
        <Link href={Routes.Public.Home} passHref>
          <a>
            <img src={logoBlack} alt="BSCS Logo" />
          </a>
        </Link>
        <StylNavbar isOpen={isMenuOpen}>
          <HeaderColumn>
            <HeaderMainNav
              pages={pages}
              onButtonSelect={toggleBottomNav}
              activeButton={activeBottomNav}
            />
          </HeaderColumn>

          <HeaderColumn>
            <ViewToggle />
            <UserDropdown />
          </HeaderColumn>
        </StylNavbar>

        <Burger isOpen={isMenuOpen} toggleMenu={handleToggleMenu} />
      </HeaderContainer>

      {activeBottomNav !== null && isMenuOpen && (
        <HeaderBottomNav data={pages[activeBottomNav].links} />
      )}
    </header>
  );
}

function Burger({
  isOpen,
  toggleMenu,
}: {
  isOpen: boolean;
  toggleMenu: () => void;
}) {
  return (
    <StylBurger isOpen={isOpen} onClick={toggleMenu}>
      <span className="box">
        <span className="inner"></span>
      </span>
    </StylBurger>
  );
}

export default HeaderComponent;
