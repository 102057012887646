export default {
  SignIn: "/app/sign-in",
  SignUp: "/app/sign-up",
  ResetPassword: "/app/reset-password",
  GenerateToken: "/app/reset-password",

  UsersList: "/app/users",
  UsersAdd: "/app/users/new",
  UserEdit: "/app/users/edit",
  UserProfile: "/app/profile",

  // Items
  Items: "/app/items",
  ItemsAdd: "/app/items/new",
  ItemsEdit: "/app/items/edit",
  Forms: "/app/forms",
  FormsAdd: "/app/forms/new",
  FormEdit: "/app/forms/edit",
  StarredItems: "/app/items/starred",

  Tests: "/app/tests",
  TestsPremade: "/app/tests/premade",
  MyTests: "/app/tests/me",
  TestsAdd: "/app/tests/new",
  TestsEdit: "/app/tests/edit",
  TestDetails: "/app/tests",
  TestResponses: "/app/tests/responses",
  TestResponseReview: "/app/tests/responses/review",

  Projects: "/app/content/projects",
  ProjectsAdd: "/app/content/projects/new",
  ProjectsEdit: "/app/content/projects/edit",

  Misconceptions: "/app/content/misconceptions",
  MisconceptionsAdd: "/app/content/misconceptions/new",
  MisconceptionsEdit: "/app/content/misconceptions/edit",

  KeyIdeas: "/app/content/keyideas",
  KeyIdeasAdd: "/app/content/keyideas/new",
  KeyIdeasEdit: "/app/content/keyideas/edit",

  Topics: "/app/content/topics",
  TopicsAdd: "/app/content/topics/new",
  TopicsEdit: "/app/content/topics/edit",

  Data: "/app/data",
  DataAdd: "/app/data#add_new",

  InvalidAccess: "/app/invalid-access",
};
