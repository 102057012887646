export function isOneOf<T>(
  value: string | number | T,
  data: string[] | number[] | T[],
) {
  if (Array.isArray(data)) {
    // @ts-ignore
    return data.indexOf(value) > -1;
  }
}

export function removeObjectFromArray(data: any, key: string, value: any) {
  if (!data) throw new Error("no data passed");
  if (!Array.isArray(data)) throw new Error("data is not an array");

  data = data.slice();
  const indexOfDeletedItem = data.findIndex((obj: any) => obj[key] === value);
  data?.splice(indexOfDeletedItem, 1);

  return data;
}

export function removeElementFromArray(
  data: any[],
  value: string | number | boolean,
) {
  if (!data) throw new Error("no data passed");

  data = data.slice();
  const indexOfDeletedItem = data.findIndex(
    (el: string | number | boolean) => el === value,
  );
  data?.splice(indexOfDeletedItem, 1);

  return data;
}
