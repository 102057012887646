import { useEffect, useState } from "react";
import eventManager from "../eventManager";
import useNotification from "../hooks/useNotification";
import { LoaderEvents } from ".";

const useLoader = () => {
  const [loaderProps, setLoaderProps] = useState({
    show: false,
    message: "",
  });
  const { notifyInfo } = useNotification();
  const showLoader = (props: any) => {
    setLoaderProps({ show: true, message: props.detail.message });
  };
  const hideLoader = () => setLoaderProps({ show: false, message: "" });

  useEffect(() => {
    if (loaderProps.show) {
      const timer = setTimeout(
        () => notifyInfo(loaderProps.message || "Loading"),
        1000,
      );
      return () => clearTimeout(timer);
    }
  }, [loaderProps]);

  const attachLoaderEvents = () => {
    eventManager.subscribe(LoaderEvents.ShowLoader, showLoader);
    eventManager.subscribe(LoaderEvents.HideLoader, hideLoader);
  };

  const detachLoaderEvents = () => {
    eventManager.unsubscribe(LoaderEvents.ShowLoader, showLoader);
    eventManager.unsubscribe(LoaderEvents.HideLoader, hideLoader);
  };

  useEffect(() => {
    attachLoaderEvents();

    return () => {
      detachLoaderEvents();
    };
  }, []);

  return loaderProps.show;
};

export default useLoader;
