import { format as formatFns } from "date-fns";

type dateArg = string | number | Date;

export function format(date: dateArg, format: string) {
  try {
    return formatFns(ensureIsDate(date), format);
  } catch (err) {
    return "invalid date format";
  }
}

export function formatDate(date: dateArg) {
  return format(date, "MMM dd, yyyy 'at' hh:mm a");
}

export function formatShort(date: dateArg) {
  return format(date, "MM-dd-yy");
}

function ensureIsDate(date: dateArg) {
  const timestamp = Number(date);
  if (!isNaN(timestamp)) return new Date(timestamp);
  return new Date(date);
}
