import { UserRole } from "lib/auth/authContext/authContext.d";

function isRole(
  role: UserRole | string,
  compareRole: UserRole | string,
): boolean {
  return role === compareRole;
}

const roleHierarchy: UserRole[] = [
  UserRole.User,
  UserRole.Researcher,
  UserRole.Admin,
];

export function roleIsAtLeast(userRole: UserRole) {
  const roleLevel = roleHierarchy.indexOf(userRole);

  function compareWithHierarchy(role: UserRole) {
    return () => {
      if (roleLevel < 0) return false;
      return roleLevel >= roleHierarchy.indexOf(role);
    };
  }

  return {
    admin: compareWithHierarchy(UserRole.Admin),
    researcher: compareWithHierarchy(UserRole.Researcher),
    registered: compareWithHierarchy(UserRole.User),
  };
}

export function compareRole(base: UserRole | UserRole[], compare: UserRole) {
  if (Array.isArray(base)) {
    return base.includes(compare);
  }
  return base === compare;
}

export function isAdmin(role: UserRole | string): boolean {
  return isRole(role, "ADMIN");
}

export function isTeacher(role: UserRole | string): boolean {
  return isRole(role, "TEACHER");
}

export function isRegistered(role: UserRole | string): boolean {
  return isRole(role, "REGISTERED");
}
