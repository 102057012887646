import React, { useContext } from "react";
import { useAuth } from "lib/auth/authContext";
import { roleIsAtLeast } from "lib/helpers";
import ViewContext from "lib/view";
import ToggleInput, { ToggleSpan } from "../../Common/ToggleInput";
import { HeaderSwitcher } from "./Header.styles";

export default function ViewToggle() {
  const { user: currentUser } = useAuth();
  const { view, toggleView, viewToggleDisabled } = useContext(ViewContext);

  const showToggle =
    currentUser && roleIsAtLeast(currentUser?.role).researcher();

  if (!showToggle) {
    return null;
  }

  return (
    <HeaderSwitcher
      title={
        viewToggleDisabled
          ? "view change not available on that page"
          : "change view"
      }
    >
      <ToggleSpan className={viewToggleDisabled ? "disabled" : undefined}>
        Public View
      </ToggleSpan>
      <ToggleInput
        handleToggle={toggleView}
        checked={view !== "admin"}
        disabled={viewToggleDisabled}
      />
    </HeaderSwitcher>
  );
}
