import React from "react";
import { MenuButton } from "@reach/menu-button";
import styled from "styled-components";
import chevronBlack from "../../../../assets/icons/chevron-top.svg";
import userIcon from "../../../../assets/icons/user.svg";

function ActiveUserDetails({ name }: { name: string }): JSX.Element {
  return (
    <MenuButton as={Wrapper}>
      <div
        style={{
          display: "flex",
          flexWrap: "nowrap",
          alignItems: "center",
        }}
      >
        <img src={userIcon} alt="user icon" style={{ marginRight: "0.5rem" }} />
        <span>
          Hi,&nbsp;<strong>{name}</strong>
        </span>
      </div>
      <span aria-hidden>
        <img src={chevronBlack} alt="chevron icon" />
      </span>
    </MenuButton>
  );
}

export default ActiveUserDetails;

const Wrapper = styled.div`
  background-color: #f5f7fa;
  padding: 0.5rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  min-width: min-content;

  & > img:first-child {
    margin-right: 1.25rem;
  }

  & > img:last-child {
    transform: rotate(0deg);
    opacity: 0.6;
    margin-left: 3rem;
    width: 1.25rem;
  }
`;
