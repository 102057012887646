import React from "react";
import Link from "next/link";
import {
  Footer,
  FooterContainer,
  FooterLinksHeading,
  FooterLink,
  FooterNav,
  FooterSocialIconsBox,
  FooterCopyrightBox,
  FooterBackgroundIcon,
} from "./Footer.styles";
import facebookCircle from "../../assets/icons/facebook-circle.svg";
import facebook from "../../assets/icons/facebook.svg";
import backgroundImageDark from "../../assets/icons/footer-bg-image-dark.svg";
import backgroundImageLight from "../../assets/icons/footer-bg-image-light.svg";
import linkedInCircle from "../../assets/icons/linkedIn-circle.svg";
import linkedIn from "../../assets/icons/linkedIn.svg";
import twitterCircle from "../../assets/icons/twitter-circle.svg";
import twitter from "../../assets/icons/twitter.svg";
import logoBlack from "../../assets/logos/logo-black.svg";
import logoWhite from "../../assets/logos/logo-white.svg";

interface FooterProps {
  dark?: boolean;
  wide?: boolean;
}

function FooterComponent(props: FooterProps) {
  return (
    <Footer dark={props.dark}>
      <FooterContainer wide={props.wide}>
        <FooterNav>
          <div>
            <FooterLinksHeading>Contact</FooterLinksHeading>
            <ul>
              <li>
                <FooterLink href="tel:719-531-5550">719-531-5550</FooterLink>
              </li>
              <li>
                <FooterLink href="mailto: assessment@bscs.org">
                  assessment@bscs.org
                </FooterLink>
              </li>
            </ul>
            <FooterSocialIconsBox>
              <a
                href="https://www.facebook.com/BSCSORG/"
                aria-label="go to facebook"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={props.dark ? facebook : facebookCircle}
                  alt="facebook"
                />
              </a>
              <a
                href="https://twitter.com/BSCSorg"
                target="_blank"
                aria-label="go to twitter"
                rel="noreferrer"
              >
                <img src={props.dark ? twitter : twitterCircle} alt="twitter" />
              </a>
              <a
                href="https://www.linkedin.com/company/bscs/"
                target="_blank"
                aria-label="go to Linkedin"
                rel="noreferrer"
              >
                <img
                  src={props.dark ? linkedIn : linkedInCircle}
                  alt="linkedIn"
                />
              </a>
            </FooterSocialIconsBox>
          </div>
          <div>
            <FooterLinksHeading>Site map</FooterLinksHeading>
            <ul>
              {/* <li>
                <Link href="#" passHref>
                  <FooterLink>About</FooterLink>
                </Link>
              </li> */}
              <li>
                <Link href="/projects" passHref>
                  <FooterLink>Projects</FooterLink>
                </Link>
              </li>
              <li>
                <Link href="/items" passHref>
                  <FooterLink>Items</FooterLink>
                </Link>
              </li>
              <li>
                <Link href="/support" passHref>
                  <FooterLink>Contact</FooterLink>
                </Link>
              </li>
            </ul>
          </div>
          {/* <div>
            <FooterLinksHeading>Info</FooterLinksHeading>
            <ul>
              <li>
                <Link href="#" passHref>
                  <FooterLink>Privacy policy</FooterLink>
                </Link>
              </li>
              <li>
                <Link href="#" passHref>
                  <FooterLink>FAQ</FooterLink>
                </Link>
              </li>
              <li>
                <Link href="#" passHref>
                  <FooterLink>Work at BSCS</FooterLink>
                </Link>
              </li>
            </ul>
          </div> */}
        </FooterNav>
        <FooterCopyrightBox>
          <Link href="/" passHref>
            <a aria-label="go to homepage">
              <img src={props.dark ? logoWhite : logoBlack} alt="logo" />
            </a>
          </Link>
          <p>
            © {new Date().getFullYear()} BSCS Science Learning All rights
            reserved.
          </p>
        </FooterCopyrightBox>
        <FooterBackgroundIcon
          src={props.dark ? backgroundImageDark : backgroundImageLight}
          alt={"circle with dots"}
        />
      </FooterContainer>
    </Footer>
  );
}

export default FooterComponent;
