import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import styled from "styled-components";
import media from "../../../lib/theme/media";
import { Container } from "../../Common/Grid";

export const HeaderBottomNavWrapper = styled.div`
  background-color: rgba(248, 250, 255);
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 2;
`;

export const HeaderBottomNavList = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media ${media.large} {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fit, minmax(60px, max-content));
  }

  @media ${media.xxLarge} {
    grid-gap: 3rem;
  }
`;

export const HeaderBottomNavLink = styled.a<{ isActive: boolean }>`
  font-size: 1rem;
  font-family: "Kulim Park", sans-serif;
  color: ${({ theme }) => theme.colors.header_link_bottom};
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 1rem 1rem;

  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 2px;
    left: 0;
    transition: opacity 300ms ease-in-out;
    will-change: opacity;
    background-color: ${({ theme }) => theme.colors["header_link_border"]};
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  }

  &:focus,
  &:hover {
    &:after {
      opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
    }
  }

  &:hover img {
    opacity: 1;
    box-shadow: 0 2px 9px RGBA(72, 69, 88, 0.2);
  }

  img {
    width: 1.825rem;
    margin-right: 1rem;
    padding: 0.125rem;
    border-radius: 8px;
    border: solid 1px rgba(8, 19, 47, 0.25);
    opacity: ${({ isActive }) => (isActive ? "1" : "0.3")};
    transition: opacity 300ms ease-in-out, box-shadow 300ms ease-in-out;
  }
`;

function HeaderBottomNav({ data }: any) {
  const { pathname, query } = useRouter();

  const absolutePathActiveIndex = data.findIndex(
    (el: { href: string }) => el.href === pathname,
  );

  function checkIsActive(el: any, index: number) {
    if (query.ref && query.ref !== "") {
      return el.href.split("/").includes(query.ref);
    } else {
      return absolutePathActiveIndex >= 0
        ? absolutePathActiveIndex === index
        : pathname.includes(el.href);
    }
  }

  return (
    <HeaderBottomNavWrapper>
      <Container>
        <nav>
          <HeaderBottomNavList>
            {data.map((el: any, i: number) => {
              const isActive = checkIsActive(el, i);
              return (
                <li key={i}>
                  <Link href={el.href} passHref>
                    <HeaderBottomNavLink isActive={isActive} tabIndex={0}>
                      <img src={el.icon} alt={"icon"} />
                      {el.text}
                    </HeaderBottomNavLink>
                  </Link>
                </li>
              );
            })}
          </HeaderBottomNavList>
        </nav>
      </Container>
    </HeaderBottomNavWrapper>
  );
}

export default HeaderBottomNav;
