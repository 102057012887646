import React, { ReactNode } from "react";
import { Loader } from "shared/src/lib/loader";
import GlobalStyles from "./GlobalStyles";

function LayoutComponent({ children }: { children: ReactNode }): JSX.Element {
  return (
    <>
      <GlobalStyles />
      <Loader />
      {children}
    </>
  );
}

export default LayoutComponent;
