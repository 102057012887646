import React, { useState } from "react";
import Link from "next/link";
import type { Router } from "next/router";
import { withRouter } from "next/router";
import styled from "styled-components";
import Routes from "lib/routes";
import media from "lib/theme/media";
import { ActiveNavIndex } from "components/App/Header/Header.component";
import HeaderBottomNav from "components/App/Header/HeaderBottomNav";
import { NavButton } from "components/App/Header/HeaderMainNav";
import { Flex } from "components/Common/Grid";
import {
  HeaderColumn,
  HeaderContainer,
  HeaderLink,
} from "components/Common/Header";
import {
  CenterLinks,
  GuestButtonColor,
} from "components/Common/Header/Header.d";
import chevronBlack from "assets/icons/chevron-top.svg";
import iconBook from "assets/icons/icon-book.svg";
import iconBookmark from "assets/icons/icons-bookmark.svg";
import iconList from "assets/icons/icons-bullet-list.svg";
import logoBlack from "assets/logos/logo-black.svg";
import UserButton from "./UserButton";

type HeaderProps = GuestButtonColor & CenterLinks;

const pages = [
  { href: Routes.Public.Projects, text: "Projects", icon: iconBook },
  { href: Routes.Public.Items, text: "Items", icon: iconList },
  {
    text: "Content",
    links: [
      // { href: Routes.Public.Topics, text: "Topics", icon: iconBookOpen },
      { href: Routes.Public.KeyIdeas, text: "Key ideas", icon: iconBookmark },
      // {
      //   href: Routes.Public.Misconceptions,
      //   text: "Misconceptions",
      //   icon: iconMisconceptions,
      // },
    ],
  },
  { href: Routes.Public.Support, text: "Support" },
];

const LinksList = styled(Flex.Row)`
  height: 100%;

  & > li {
    display: flex;
    align-items: center;
    padding: 0 0.125rem;
  }

  @media ${media.xxLarge} {
    & > li {
      padding: 0 0.5rem;
    }
  }
`;

const HeaderWrapper = styled.header<{ opaque?: boolean }>`
  background-color: ${({ opaque }) =>
    opaque ? "rgb(240, 244, 248, 0.95)" : "transparent"};
`;

function HeaderComponent({
  buttonTextWhite,
  centerLinks = true,
}: HeaderProps): JSX.Element {
  // const { pathname } = useRouter();
  // const initialActiveNavIndex = getInitialActiveNavIndex()
  const [activeBottomNav, setActiveBottomNav] = useState<ActiveNavIndex>(null);

  function toggleBottomNav(index: number): void {
    if (index === activeBottomNav) setActiveBottomNav(null);
    else setActiveBottomNav(index);
  }

  const isBottomNavOpen = activeBottomNav !== null;

  return (
    <HeaderWrapper opaque={buttonTextWhite && isBottomNavOpen}>
      <HeaderContainer wide>
        <HeaderColumn>
          <Logo />
        </HeaderColumn>

        <HeaderColumn>
          <LinksList
            as="ul"
            justify={centerLinks ? "center" : "start"}
            align="center"
          >
            {pages.map((page: any, index: number) => {
              return (
                <li key={index} style={{ height: "100%" }}>
                  {page.links ? (
                    <NavButton
                      value={index}
                      onSelect={toggleBottomNav}
                      isActive={index === activeBottomNav}
                    >
                      {page.text} <img src={chevronBlack} alt="dropdown icon" />
                    </NavButton>
                  ) : (
                    <Link href={page.href} passHref>
                      <HeaderLink>{page.text}</HeaderLink>
                    </Link>
                  )}
                </li>
              );
            })}
          </LinksList>
        </HeaderColumn>

        <UserButton buttonTextWhite={buttonTextWhite && !isBottomNavOpen} />
      </HeaderContainer>

      {isBottomNavOpen && (
        <HeaderBottomNav data={pages[activeBottomNav].links} />
      )}
    </HeaderWrapper>
  );
}

const Logo = () => (
  <Link href={Routes.Public.Home} passHref>
    <a>
      <img src={logoBlack} alt="BSCS Logo" />
    </a>
  </Link>
);

type PropsWithRouter = HeaderProps & { router: Router };
export default withRouter<PropsWithRouter>(HeaderComponent);
