import { useContext } from "react";
import NotificationContext from "../notification";

export default function useNotification() {
  const { notify } = useContext(NotificationContext);

  function notifyError(msg: string) {
    return notify("error", msg);
  }

  function notifySuccess(msg: string) {
    return notify("success", msg);
  }

  function notifyInfo(msg: string) {
    return notify("info", msg);
  }

  return {
    notifyError,
    notifySuccess,
    notifyInfo,
  };
}
