import React from "react";
import LoadingOverlay from "../../components/Common/LoadingOverlay";
import useLoader from "./useLoader";

export default function Loader() {
  const showLoader = useLoader();

  if (!showLoader) {
    return null;
  }

  return <LoadingOverlay />;
}
