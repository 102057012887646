interface EventManager {
  element: Element | null;
  subscribe(eventName: string, callback: any): void;
  broadcast(eventName: string, payload?: any): void;
  unsubscribe(eventName: string, callback: any): void;
}

const eventManager: EventManager = {
  element: null,
  subscribe(eventName: string, callback: any) {
    this.element = document.querySelector("#__next");
    if (!this.element) {
      throw new Error("Invalid EventManager root !");
    }
    this.element.addEventListener(eventName, callback);
  },
  broadcast(eventName: string, payload = {}) {
    if (this.element) {
      this.element.dispatchEvent(
        new CustomEvent(eventName, {
          detail: payload,
        }),
      );
    }
  },
  unsubscribe(eventName: string, callback: any) {
    if (this.element) {
      this.element.removeEventListener(eventName, callback);
    }
  },
};

export default eventManager;
