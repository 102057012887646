export default {
  Home: "/",
  Projects: "/projects",
  ProjectDetails: (id: string) => `/projects/${id}`,
  Items: "/items",
  ItemDetails: (id: string) => `/items/${id}`,
  ItemResults: (id: string) => `/items/results/${id}`,
  Support: "/support",
  KeyIdeas: "/keyideas",
  KeyIdeaDetails: (id: string) => `/keyideas/${id}`,
};
