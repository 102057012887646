import React, { ReactNode } from "react";
import styled from "styled-components";
import Layout from "./Layout";
import { useAuth } from "../../lib/auth/authContext";
import AppHeader from "../App/Header/Header.component";
import Footer from "../Footer/Footer.component";
import PublicHeader from "../Public/Header";

const Main = styled.main`
  background-color: ${({ theme }) => theme.colors["app_background"]};
`;

// TODO: adds User checking on Layout level
// to prevent page rendering for non-loggedin users

function AppLayoutComponent({
  children,
  wide,
}: {
  children: ReactNode;
  wide?: boolean;
}): JSX.Element {
  const { user } = useAuth();

  const Header = !!user ? AppHeader : PublicHeader;

  return (
    <Layout>
      <Header wide={wide} />
      <Main>{children}</Main>
      <Footer wide={wide} />
    </Layout>
  );
}

export default AppLayoutComponent;
