const Tests = {
  take: (testID: string) => `/api/test/${testID}/take`,
};

export default {
  Profile: "/api/profile",
  Session: "/api/user/session",
  UniqueCode: "/api/unique-code",
  UniqueSequentialItemCode: "/api/unique-sequential-item-code",
  Tests,
};
