import React from "react";
import Link from "next/link";
import { ActiveNavIndex } from "./Header.component";
import { HeaderDropdownButton } from "./Header.styles";
import chevronBlack from "../../../assets/icons/chevron-top.svg";
import { LinksList, HeaderLink } from "../../Common/Header";
import { PageGroup, Page } from "../../Common/Header/Header.d";

interface MainNavProps {
  pages: (PageGroup | Page)[];
  onButtonSelect: (index: number) => void;
  activeButton?: ActiveNavIndex;
}

function HeaderNav({
  pages,
  onButtonSelect,
  activeButton,
}: MainNavProps): JSX.Element {
  return (
    <LinksList>
      {/* page is not any in fact it's type Page or PageGroup */}
      {pages.map((page: any, index: number) => {
        return (
          <li key={index}>
            {page.links ? (
              <NavButton
                value={index}
                onSelect={onButtonSelect}
                isActive={index === activeButton}
              >
                {page.text} <img src={chevronBlack} alt="dropdown icon" />
              </NavButton>
            ) : (
              <Link href={page.href} passHref>
                <HeaderLink>{page.text}</HeaderLink>
              </Link>
            )}
          </li>
        );
      })}
    </LinksList>
  );
}

interface ButtonProps {
  value: number;
  onSelect: (value: number) => void;
  isActive?: boolean;
  children?: React.ReactNode;
}

export function NavButton({
  value,
  onSelect,
  ...rest
}: ButtonProps): JSX.Element {
  function handleSelect() {
    onSelect(value);
  }
  return <HeaderDropdownButton onClick={handleSelect} {...rest} />;
}

export default HeaderNav;
