import styled from "styled-components";
import media from "lib/theme/media";
import { Container } from "components/Common/Grid";

export const HeaderContainer = styled(Container)`
  padding: 0.938rem 0 0;
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;

  grid-column-gap: 1rem;
  @media ${media.xLarge} {
    grid-column-gap: 4rem;
  }
  @media ${media.xxLarge} {
    grid-column-gap: 5.3rem;
  }
`;

export const HeaderColumn = styled.div<{ padding?: boolean }>`
  display: flex;
  align-items: center;
  height: 60px;
`;
export const ButtonWrapper = styled.div<{ dualColumn?: boolean }>`
  display: grid;
  grid-template-columns: ${({ dualColumn }) =>
    dualColumn ? "90px 209px" : "209px"};
  grid-gap: 28px;
  height: 60px;
`;
export const LinksList = styled.ul`
  display: flex;
  width: 58%;
  height: 60px;
  justify-content: space-around;

  & > li {
    display: flex;
    align-items: center;
    padding: 0 0.125rem;
  }

  @media ${media.xxLarge} {
    & > li {
      padding: 0 0.5rem;
    }
  }
`;

LinksList.defaultProps = {
  role: "list",
};

export const HeaderLink = styled.a<{ isActive?: boolean }>`
  color: ${({ theme }) => theme.colors["header_link_main"]};
  display: flex;
  align-items: center;
  padding: 0.5rem 2rem;
  height: 100%;
  position: relative;
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};

  &:after {
    position: absolute;
    content: "";
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 2px;
    transition: opacity 300ms ease-in-out;
    will-change: opacity;
    background-color: ${({ theme }) => theme.colors["header_link_border"]};
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  }

  &:hover,
  &:focus {
    &:after {
      opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
    }
  }
`;
