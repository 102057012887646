import { useEffect } from "react";
import useSWR from "swr";
import Routes from "../routes";

const CHECK_USER_ENDPOINT = Routes.Api.Session;
const fetcher = (url: string) =>
  fetch(url, { method: "POST" }).then((res) => res.json());

export function useAuthPublic() {
  const { data, error } = useSWR(CHECK_USER_ENDPOINT, fetcher);

  useEffect(() => {
    if (error) {
      console.error("Error checking login status:", error);
    }
  }, [error]);

  return data?.user;
}
