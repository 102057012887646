import styled from "styled-components";
import media from "../../../lib/theme/media";
import { Container } from "../../Common/Grid";

export const HeaderContainer = styled(Container)<{ isMenuOpen: boolean }>`
  padding: 1.55rem 0 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  position: relative;

  @media ${media.large} {
    display: grid;
    grid-templatee-rows: 1;
    grid-template-columns: auto 1fr;
    justify-content: space-between;
    align-items: center;

    grid-column-gap: 3%;
  }

  @media ${media.xLarge} {
    grid-column-gap: 2rem;
  }
  @media ${media.xxLarge} {
    grid-column-gap: 3rem;
  }
`;

export const HeaderColumn = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  min-height: 60px;
`;

export const LinksList = styled.ul`
  display: flex;
  width: 100%;
  min-height: 60px;
  justify-content: center;

  & > li {
    display: flex;
    align-items: center;
    padding: 0 0.125rem;
  }

  @media ${media.xxLarge} {
    & > li {
      padding: 0 0.5rem;
    }
  }
`;

LinksList.defaultProps = {
  role: "list",
};

export const HeaderSwitcher = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  span {
    display: block;
    padding-right: 20px;
    color: ${({ theme }) => theme.colors["header_link"]};
  }
`;

interface HeaderDropdownButtonProps {
  isActive?: boolean;
}
export const HeaderDropdownButton = styled.button<HeaderDropdownButtonProps>`
  cursor: pointer;
  display: flex;
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};
  align-items: center;
  padding: 0.5rem 1.75rem 0.5rem 2rem;
  text-align: center;
  height: 100%;
  color: ${({ theme }) => theme.colors["header_link_main"]};
  background-color: transparent;
  border: none;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 2px;
    left: 0;
    transition: opacity 300ms ease-in-out;
    will-change: opacity;
    background-color: ${({ theme }) => theme.colors["header_link_border"]};
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  }

  &:focus,
  &:hover {
    &:after {
      opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
    }
  }

  & > img {
    transform: rotate(180deg);
    width: 1.25rem;
    margin-left: 0.25rem;

    @media ${media.xxLarge} {
      margin-left: 1rem;
    }
  }
`;

export const StylNavbar = styled.nav<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  grid-row: 2/2;
  grid-column: 1/3;

  @media ${media.large} {
    grid-row: 1;
    grid-column: 2/2;
    display: grid;
    grid-template-columns: auto auto;
  }

  @media ${media.xLarge} {
    grid-column-gap: 2rem;
  }
  @media ${media.xxLarge} {
    grid-column-gap: 3rem;
  }
`;

export const StylBurger = styled.button<{ isOpen: boolean }>`
  background-color: transparent;
  color: inherit;
  min-width: 1.4rem;
  font: inherit;
  display: inline-flex;
  overflow: visible;
  margin: 0;
  padding: 10px;
  text-transform: none;
  border: 0;
  outline: none;
  cursor: pointer;
  z-index: 10;

  justify-self: right;

  @media ${media.large} {
    display: none;
  }

  & .box {
    top: 0;
    position: relative;
    display: inline-block;
    width: 1.4rem;
    height: 1.3rem;
  }

  & .inner {
    top: ${({ isOpen }) => (isOpen ? "-2px" : "0")};
    display: block;

    ${({ isOpen }) =>
      isOpen && `transform: translate3d(0, 10px, 0) rotate(45deg);`}
  }

  & .inner,
  & .inner:after,
  & .inner:before {
    position: absolute;
    width: 1.4rem;
    height: 2px;
    transition-timing-function: ease;
    transition-duration: 0.35s;
    transition-property: transform, opacity;
    border-radius: 4px;
    background-color: black;
    outline: none;
  }

  & .inner:before,
  & .inner:after {
    display: block;
    content: "";
  }

  & .inner:before {
    top: 8px;
    transition-duration: ${({ isOpen }) => (isOpen ? "0.15s" : "0.35s")};
    opacity: ${({ isOpen }) => (isOpen ? "0" : "1")};
  }

  & .inner:after {
    bottom: -16px;
    ${({ isOpen }) =>
      isOpen && `transform: translate3d(0, -16px, 0) rotate(-90deg);`}
  }
`;
