import React from "react";
import { Menu } from "@reach/menu-button";
import Link from "next/link";
import { useAuth } from "lib/auth/authContext";
import { User } from "lib/auth/authContext/authContext.d";
import { isAdmin } from "lib/helpers";
import Routes from "lib/routes/app";
import chevronBlack from "assets/icons/chevron-top.svg";
import iconSettings from "assets/icons/icon-settings.svg";
import iconUsers from "assets/icons/icon-users.svg";
import iconLogin from "assets/icons/icons-log-in.svg";
import userIcon from "assets/icons/user.svg";
import ActiveUserDetails from "./ActiveUserDetails.component";
import {
  UserDropdownWrapper,
  UserDropdownMenuButton,
  UserDropdownMenuList,
  UserDropdownMenuItem,
  StyledUserDropdownLink,
} from "./UserDropdown.styles";

interface UserDropdownProps {
  currentUser?: User | null;
  logout: () => void;
}

function UserDropdownComponent({ currentUser, logout }: UserDropdownProps) {
  return (
    <UserDropdownWrapper>
      <Menu>
        <>
          <UserDropdownMenuButton>
            <img src={userIcon} alt="user icon" />
            <img src={chevronBlack} alt="chevron icon" aria-hidden />
          </UserDropdownMenuButton>

          <UserDropdownMenuList portal={false}>
            <ActiveUserDetails
              name={currentUser?.name || currentUser?.username || ""}
            />

            {currentUser && isAdmin(currentUser.role) && (
              <Link href={Routes.UsersList} passHref>
                <StyledUserDropdownLink>
                  <img src={iconUsers} alt="setting icon" />
                  <span>Users list</span>
                </StyledUserDropdownLink>
              </Link>
            )}

            <Link href={Routes.UserProfile}>
              <StyledUserDropdownLink>
                <img src={iconSettings} alt="setting icon" />
                <span>Settings</span>
              </StyledUserDropdownLink>
            </Link>

            {/* LOGOUT */}
            {/* arrow function is here just because of it's required by onSelect type*/}
            <UserDropdownMenuItem onSelect={() => logout()}>
              <img src={iconLogin} alt="logout icon" />
              <span>Sign out</span>
            </UserDropdownMenuItem>
          </UserDropdownMenuList>
        </>
      </Menu>
    </UserDropdownWrapper>
  );
}

function UserDropdownContainer() {
  const { user: currentUser, logout } = useAuth();

  return <UserDropdownComponent currentUser={currentUser} logout={logout} />;
}

export default UserDropdownContainer;
