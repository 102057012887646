import Link from "next/link";
import { ButtonWrapper } from "components/Common/Header";
import Button from "components/Common/Button";
import { useAuthPublic } from "lib/hooks/useAuthPublic";
import Routes from "lib/routes";

export default function UserButton({
  buttonTextWhite,
}: {
  buttonTextWhite?: boolean;
}) {
  const user = useAuthPublic();

  if (user === undefined) {
    return null;
  }

  if (user) {
    return (
      <Link href={Routes.App.Items} passHref>
        <Button as="a" primary>
          Go to app
        </Button>
      </Link>
    );
  }

  return (
    <ButtonWrapper dualColumn>
      <Link href={Routes.App.SignIn} passHref>
        <Button
          as="a"
          transparent
          style={{ color: (buttonTextWhite && "#FFF") || undefined }}
        >
          Sign in
        </Button>
      </Link>
      <Link href={Routes.App.SignUp} passHref>
        <Button as="a" primary>
          Register
        </Button>
      </Link>
    </ButtonWrapper>
  );
}
