import eventManager from "../eventManager/eventManager";

export enum LoaderEvents {
  ShowLoader = "SHOW_LOADER",
  HideLoader = "HIDE_LOADER",
}

const loaderEvents = {
  showLoader(message?: string) {
    eventManager.broadcast(LoaderEvents.ShowLoader, { message });
  },
  hideLoader(message?: string) {
    eventManager.broadcast(LoaderEvents.HideLoader, { message });
  },
};

export default loaderEvents;
