import Routes from "lib/routes";
import iconBook from "assets/icons/icon-book.svg";
import iconBookOpen from "assets/icons/icon-bookopen.svg";
import iconDatabase from "assets/icons/icon-database.svg";
import iconMisconceptions from "assets/icons/icon-misconceptions.svg";
import iconBookmark from "assets/icons/icons-bookmark.svg";
import iconList from "assets/icons/icons-bullet-list.svg";
import iconStar from "assets/icons/icons-star.svg";
import iconForm from "assets/icons/merge-cells.svg";
import iconAdd from "assets/icons/plus-add.svg";

export const AdminPages = [
  {
    text: "Items",
    links: [
      { href: Routes.App.Items, text: "Items", icon: iconList },
      { href: Routes.App.Forms, text: "Forms", icon: iconForm },
      { href: Routes.App.ItemsAdd, text: "Add new item", icon: iconAdd },
    ],
  },
  {
    text: "Tests",
    links: [
      { href: Routes.App.Tests, text: "All tests", icon: iconList },
      {
        href: Routes.App.TestsPremade,
        text: "Premade Tests",
        icon: iconDatabase,
      },
      { href: Routes.App.TestsAdd, text: "Create new test", icon: iconAdd },
    ],
  },
  {
    text: "Content",
    links: [
      { href: Routes.App.Projects, text: "Projects", icon: iconBook },
      { href: Routes.App.Topics, text: "Topics", icon: iconBookOpen },
      { href: Routes.App.KeyIdeas, text: "Key ideas", icon: iconBookmark },
      {
        href: Routes.App.Misconceptions,
        text: "Misconceptions",
        icon: iconMisconceptions,
      },
    ],
  },
  {
    text: "Data",
    links: [
      { href: Routes.App.Data, text: "All data files", icon: iconBookmark },
      { href: Routes.App.DataAdd, text: "Add new file", icon: iconAdd },
    ],
  },
  { href: Routes.Public.Support, text: "Support" },
];

export const TeacherPages = [
  {
    text: "Items",
    links: [
      { href: Routes.App.Items, text: "Items", icon: iconList },
      { href: Routes.App.StarredItems, text: "My item bank", icon: iconStar },
    ],
  },
  {
    text: "Tests",
    links: [
      { href: Routes.App.MyTests, text: "My tests", icon: iconList },
      {
        href: Routes.App.TestsPremade,
        text: "Tests database",
        icon: iconDatabase,
      },
      { href: Routes.App.TestsAdd, text: "Create new test", icon: iconAdd },
    ],
  },
  { href: Routes.Public.Projects, text: "Projects" },
  { href: Routes.Public.Support, text: "Support" },
];
