import isEmpty from "lodash/isEmpty";

export function removeEmptyKeysFromObject(obj: any, nullishOnly = true) {
  const newObj = {} as any;

  // clear data from null/undefined values
  for (const [key, value] of Object.entries(obj)) {
    if (isNullish(value)) continue;

    // if we want to clear empty values as well
    if (!nullishOnly) {
      if (typeof value === "number") {
        if (isNaN(value)) continue;
      } else if (isEmpty(value)) continue;
    }

    newObj[key] = value;
  }

  return newObj;
}

const isNullish = (val: any) => {
  return val === null || val === undefined;
};
