import styled, { keyframes } from "styled-components";

export default function LoadingSpinner(props: any) {
  return <Spinner {...props} />;
}

const spinning = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// margin: 60px auto;
const Spinner = styled.div`
  font-size: 6px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.3em solid rgba(255, 255, 255, 0.2);
  border-right: 1.3em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.3em solid rgba(255, 255, 255, 0.2);
  border-left: 1.3em solid ${({ theme }) => theme.colors["primary"]};
  transform: translateZ(0);
  animation: ${spinning} 1.1s infinite linear;

  &,
  &::after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
`;
