import React, { ReactNode } from "react";
import styled from "styled-components";
import Layout from "./Layout";
import Footer from "../Footer/Footer.component";
import Header from "../Public/Header";

const Main = styled.main<{ background: boolean }>`
  background-color: ${({ background }) =>
    background ? "#f0f4f8" : "transparent"};
`;

function PublicLayoutComponent({
  children,
  buttonTextWhite,
  centerLinks,
  wide,
  useBackground,
}: {
  children: ReactNode;
  buttonTextWhite?: boolean;
  centerLinks?: boolean;
  wide?: boolean;
  useBackground?: boolean;
}): JSX.Element {
  return (
    <Layout>
      <Header buttonTextWhite={buttonTextWhite} centerLinks={centerLinks} />
      <Main background={!!useBackground}>{children}</Main>
      <Footer dark wide={wide} />
    </Layout>
  );
}

export default PublicLayoutComponent;
