import React from "react";
import Portal from "@reach/portal";
import styled from "styled-components";
import LoadingSpinner from "./LoadingSpinner";

export default function LoadingOverlay() {
  React.useEffect(function toggleWindowScrolling() {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <Portal>
      <Overlay>
        <LoadingSpinner />
      </Overlay>
    </Portal>
  );
}

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(1px);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  top: 0;
  left: 0;
  z-index: 999;
`;
